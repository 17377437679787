<!-- Name -->
<script lang="ts">
export default {
  name: 'app-module-vehicle-search'
}
</script>

<script lang="ts" setup>
import { useVehiculosElStore } from '@/store/vehiculosel';

const { $api } = useNuxtApp();
const vehiculoStore = useVehiculosElStore(); 


//Cargamos filtros iniciales
const { data, error } = await $api.vehiculosEl.filters({
    pick: ['payload']
});

let initFilters = false;
let brands;
let models;
if (!error.value) {
  initFilters = JSON.parse(JSON.stringify(data.value.payload));
  vehiculoStore.initFilters = initFilters;
}

//Composable para filtros
const { filters, cleanFilters } = useFiltros('el');


const filterBrands = filters.value.find(elem => elem.action === 'marcas');
const filterModels = filters.value.find(elem => elem.action === 'modelos');

cleanFilters();

const brand = ref([]);
const model = ref([]);

/**
     * Retorna todos los modelos si no hay marca o filtrados si existen marcas
     */

const filteredModels = computed(() => {

    if(brand.value?.length && brand.value.length > 0){

      const filtered = Object.keys(filterModels.items)
      .filter(key => brand.value.includes(key))
      .reduce((obj, key) => {
        obj[key] = filterModels.items[key];
        return obj;
      }, {});

      return filtered;

    }

    return filterModels.items;

    
});


watch(brand, async (newQuestion) => {
  model.value = [];
});


const sendData =  () => {
   filterBrands.model = brand.value;
   filterModels.model = model.value;
  const router = useRouter();
  router.push('/coches-electricos/');

};



</script>

<template lang="pug">
article.section.app-module-vehicle-search
      .container.container-big
              .row 
                .col-12.text-center.mb-5 
                  img(src="~assets/images/marcos-automocion-logo-iso-color.png", alt="", width="122")
                  h1.headline--1 Marcos Automoción
      .container.position-relative
        .video-container
          .col-lg-8
            .video
            video.obj-cover(width='320', height='240', poster="/images/home-electricos-poster.jpg", autoplay, muted, loop, playsinline)
              source(src='/videos/home-electricos.mp4', type='video/mp4')
      .container.electrics-container 
        .row.align-items-center 
          .col-lg-5 
            form
              h2.headline--2 Coches Eléctricos
              p.lead Empieza tu búsqueda en nuestra amplia oferta
              .container.my-4 
                .row
                  .col-lg-6.ps-lg-0.mb-3.mb-lg-0
                    .form-floating
                      select.form-select.bg--white(id="brands",v-model="brand",:return-object="true")
                        option(:value="[]",selected) Marca
                        template(v-if="initFilters")
                          option(v-for="(item,index) in filterBrands.items", :key="index", :value="[item.nombre]") {{ item.nombre }}
                  .col-lg-6.pe-lg-0
                    .form-floating
                      select.form-select.bg--white(id="models",v-model="model",:return-object="true", :disabled="brand == ''")
                          option(:value="[]",selected) Modelo
                          template(v-if="initFilters")
                            template(v-for="(item,key,index) in filteredModels", :key="index")
                              option(v-for="(item,indexModel) in filterModels.items[key]", :key="indexModel", :value="[item.nombre]") {{ item.nombre }}

              a.btn-tertiary(@click="sendData()") Ver vehículos eléctricos

</template>

<style lang="scss" scoped>
@import 'index';
</style>